import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import { useLayoutEffect, useState } from "react";
import { jwtDecoder } from "utils/jwtDecoder";

dayjs.extend(duration);

const useTokenTimer = (type = "SUPER") => {
  let expireDate = new Date(jwtDecoder(localStorage.getItem(type)).exp * 1000);

  const [timeLeft, setTimeLeft] = useState("");

  useLayoutEffect(() => {
    const timer = setInterval(() => {
      const now = dayjs();
      const end = dayjs(expireDate);
      const diff = end.diff(now);

      if (diff <= 0) {
        clearInterval(timer);
        setTimeLeft("시간이 만료되었습니다!");
      } else {
        setTimeLeft(dayjs.duration(diff).format("D일 HH시간 mm분 ss초"));
      }
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  return { timeLeft };
};

export default useTokenTimer;
